import { useState, useRef, useEffect, useMemo } from 'react';
import { useFetch } from "../../../../../../hooks/useFetch";
import callToast, { baseUrl, payloadDateFormat } from "../../../../../../utils/helper";
import { useStore } from "../../../../../../GlobalProvider/GlobalProvider";
import { Toast } from "primereact/toast";
import { useNavigate } from 'react-router-dom';
import CreateNewAppointment from "../../../../../CreateNewAppointment/CreateNewAppointment";
import ExistingPatient from "../../../../../ExistingPatient/ExistingPatient";
import GoBack from '../../../../../GoBack/GoBack';
import app from "../../../../../../App";
import { useSelector } from 'react-redux';
const CreateAppointment = () => {
    const { token, userProfileId } = useStore();
    const toast = useRef<any>(null);
    const navigate = useNavigate()

    const [activeTab, setActiveTab] = useState('createNew');
    const [patientSearch, setPatientSearch] = useState('');
    const [doctorList, setDoctorList] = useState<any>([])
    const [searchKey, setSearchKey] = useState<any>("patientId")
    const { user, userRoles, selectedRole, selectedDoctor } = useSelector((state: any) => state.auth);




    const { data: locationData } = useFetch(`/location/list`);
    const { data: Patients } = useFetch(`/patient/search${patientSearch ? `?doctorId=${selectedDoctor?._id}&search=${patientSearch}` : ""}&key=${searchKey}`, [patientSearch],);


    const [formData, setFormData] = useState<any>({
        name: '',
        patientId: '',
        phoneNumber: '',
        email: '',
        age: '',
        ageType: 'years',
        dateOfBirth: '',
        bloodGroup: '',
        gender: '',
        maritalStatus: '',
        visitLocation: '',
        appointmentDate: payloadDateFormat(new Date()),
        appointmentType: 'new',
        doctorId: ''
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let appointmentCreatePayload: any = {
            appointmentDate: formData?.appointmentDate,
            gender: formData?.gender,
            type: formData?.appointmentType,
            name: formData?.name,
            phone: formData?.phoneNumber,
            email: formData?.email,
            dob: formData?.dateOfBirth,
            bloodGroup: formData?.bloodGroup,
            maritalStatus: formData?.maritalStatus,
            // officeLocationId: formData?.visitLocation,
            doctorId: selectedDoctor?._id,
            officeLocationId: selectedRole?.location?._id,
        }
        // if(selectedRole?.parentUsers?.length>0)appointmentCreatePayload.doctorId=selectedRole?.parentUsers[0]?._id
        if (formData?.patient && formData?.patientId) {
            appointmentCreatePayload = { ...appointmentCreatePayload, patientId: formData?.patientId, patient: formData?.patient }
        }

        try {
            //  setIsLoading(true);
            const res = await fetch(`${baseUrl}/appointment/create`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(appointmentCreatePayload),
            });
            // const resData = await res.json();
            if (res.ok) {
                callToast(toast, true, `Appointment Created successfully`)
                navigate('/')

            } else {
                console.log('error')
            }
        } catch (error: any) {

        } finally {
            //  setIsLoading(false);
        }
    };

    const handlePatientSelect = (patient: any) => {
        setFormData({
            ...formData,
            name: patient?.name,
            patientId: patient?.patientId,
            patient: patient?._id,
            phoneNumber: patient?.phone,
            email: patient?.email,
            dateOfBirth: patient?.dob?.substring(0, 10),
            gender: patient?.gender,
            maritalStatus: patient?.maritalStatus,
            bloodGroup: patient?.bloodGroup,
            address: patient?.address
        });
        setActiveTab('createNew');
        setPatientSearch('')
    };
    //DOB calculation function and hooks
    const calculateDobFromAge = (age: any, ageType: any) => {
        let dob = new Date();
        if (ageType === 'years') {
            dob.setFullYear(dob.getFullYear() - age);
        } else if (ageType === 'months') {
            dob.setMonth(dob.getMonth() - age);
        } else if (ageType === 'days') {
            dob.setDate(dob.getDate() - age);
        }
        setFormData((prev: any) => ({
            ...prev,
            dateOfBirth: dob?.toISOString().split('T')[0],
        }));
    };


    const calculateAgeFromDob = (dob: any) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        let ageType = 'years';
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        if (age < 1) {
            age = monthDiff < 0 ? 12 + monthDiff : monthDiff;
            ageType = 'months';
            if (age < 1) {
                age = dayDiff < 0 ? 30 + dayDiff : dayDiff;
                ageType = 'days';
            }
        }

        setFormData((prev: any) => ({
            ...prev,
            age: age,
            ageType: ageType,
        }));
    };

    useEffect(() => {
        if (formData.age && formData.ageType) {
            calculateDobFromAge(formData.age, formData.ageType);
        }
    }, [formData.age, formData.ageType]);

    useEffect(() => {
        if (formData.dateOfBirth) {
            calculateAgeFromDob(formData.dateOfBirth);
        }
    }, [formData.dateOfBirth]);


    // useEffect(() => {
    //     const matchingDoctor = doctorList?.find((doctor: any) => doctor?._id === userProfileId);
    //     if (matchingDoctor) {
    //         setFormData({ ...formData, doctorId: matchingDoctor._id });
    //     }
    // }, [doctorList]);


    useMemo(() => {
        if (searchKey) setPatientSearch("")
    }, [searchKey])

    // useMemo(() => {
    //     if (selectedRole?.parentUsers?.length > 0)
    //         setFormData({ ...formData, doctorId: selectedRole?.parentUsers[0]?._id })

    // }, [selectedRole?.parentUsers])

    return (
        <div>
            <Toast ref={toast}></Toast>
            <div className='container mx-auto'>
                <GoBack link={'/'} />

                <div className='flex gap-5 border-b border-b-primary mb-4'>
                    <button className={` ${activeTab === 'createNew' ? 'text-primary' : ''}`} onClick={() => setActiveTab('createNew')}>
                        New Appointment
                    </button>
                    <button className={` ${activeTab === 'existingPatient' ? 'text-primary' : ''}`} onClick={() => setActiveTab('existingPatient')}>
                        Existing Patient
                    </button>
                </div>



                {activeTab === 'createNew' && (
                    <>
                        <CreateNewAppointment
                            formData={formData}
                            handleSubmit={handleSubmit}
                            locationData={locationData}
                            doctorList={selectedRole?.parentUsers}
                            setFormData={setFormData}
                            setDoctorList={setDoctorList}

                        />
                    </>
                )}

                {activeTab === 'existingPatient' && (
                    <>
                        <ExistingPatient
                            setSearchKey={setSearchKey}
                            searchKey={searchKey}
                            patientSearch={patientSearch}
                            setPatientSearch={setPatientSearch}
                            Patients={Patients}
                            handlePatientSelect={handlePatientSelect}

                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default CreateAppointment;