import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import Logo from "../../components/Logo/Logo";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../GlobalProvider/GlobalProvider";
import "./Login.css"
import { baseUrl, inputHandler } from "../../utils/helper";
import { loginDataType } from "../../utils/types";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { setSelectedDoctor, setSelectedRole, setUserRoles } from "../../store/slices/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const toast = useRef<any>(null);
  const { setToken, setUserProfile } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [loginData, setLoginData] = useState<loginDataType | undefined>({
    email: "",
    password: "",
  });

  const dispatch = useDispatch()

  const handleLogin = async () => {
    const loginPayload: any = {
      email: loginData?.email,
      password: loginData?.password,
    }

    try {
      setIsLoading(true);
      const res = await fetch(`${baseUrl}/login`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(loginPayload),
      });
      const resData = await res.json();
      if (res.ok) {
        localStorage.setItem("isLogin", "1");
        localStorage.setItem("token", resData?.token);
        setToken(resData?.token)
        setUserProfile(resData?.user)
        localStorage.setItem("profile_id", resData?.user?._id);
        localStorage.setItem("user", JSON.stringify(resData?.user));
        dispatch(setUserRoles(resData?.userRoles))
        if (resData?.userRoles?.length > 0) {
          dispatch(setSelectedRole(resData?.userRoles[0]))
          if (resData?.userRoles[0]?.parentUsers?.length > 0) {
            dispatch(setSelectedDoctor(resData?.userRoles[0]?.parentUsers[0]))
        }
        }
       
        toast.current.show({
          severity: "success",
          summary: "success",
          detail: "Login Successfully",
          life: 3000,
        });
        navigate("/");
      } else {
        console.log('error')
      }
    } catch (error: any) {

    } finally {
      setIsLoading(false);
    }
  }



  return (
    <div className="h-screen w-full flex items-center justify-center login-bg">
      <Toast ref={toast}></Toast>
      <div className="w-[400px] lg:w-[450px] bg-gray-100 p-8 rounded shadow-xl">
        {/*<Link to={"/"}>*/}
        {/*  <Logo url={require("../../assets/logo/logo.jpg")} />*/}
        {/*</Link>*/}
        <div className="mb-5 text-center">
          <p style={{ fontSize: "20px", fontWeight: "700" }} >Sign in</p>
          <small>
            Shafa Care redefines the delivery system of primary healthcare in Bangladesh</small>
        </div>
        <div>
          <div className="mb-4">
            <label htmlFor="">Email Address</label>
            <InputText
              value={loginData?.email}
              name="email"
              type="email"
              placeholder="Type email address..."
              className="w-full p-2 border focus:outline-none"
              onChange={(e) => inputHandler(e, loginData, setLoginData)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Password</label>
            <InputText
              type="password"
              value={loginData?.password}
              name="password"
              placeholder="Password"
              className="w-full p-2 border"
              onChange={(e) => inputHandler(e, loginData, setLoginData)}
            />
          </div>
          <div className="card flex justify-content-center ">
            <Button
              label="Submit"
              className="bg-primary py-2  text-white mx-auto px-5"
              onClick={handleLogin}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
