import { BiBuildingHouse } from "react-icons/bi";
import { BsClipboard2Data, BsEnvelope, BsHouses, BsPieChart, BsTelephone } from "react-icons/bs";
import {CiCalendarDate, CiLocationOn} from "react-icons/ci";
import "./Profile.css";
import Text from "../../components/Text/Text";
import {
  AiFillLinkedin,
  AiFillTwitterSquare,
  AiOutlineFacebook,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import Tab from "../../components/Tab/Tab";
import {useStore} from "../../GlobalProvider/GlobalProvider";
import {TbGenderEpicene} from "react-icons/tb";
import moment from "moment";
import {useEffect, useState} from "react";
const Profile = () => {

  const [userProfile, setUserProfile] = useState<any>(null);
  useEffect(() => {
    const storedUserProfile = localStorage.getItem("user");
    if (storedUserProfile) {
      setUserProfile(JSON.parse(storedUserProfile));
    }
  }, []);

  return (
    <div className="container mx-auto pt-[2rem] px-4">
      <div className=" bg-white rounded-xl overflow-hidden">
        <div className="grid grid-cols-12 bg-img p-8">
          <div className="col-span-3">
            <img
              src="https://imgv3.fotor.com/images/gallery/Realistic-Male-Profile-Picture.jpg"
              alt=""
              className="h-20 w-20 rounded-full overflow-hidden"
            />
          </div>
          <div className="col-span-9">
            <h1 className="text-lg text-white">{userProfile?.name}</h1>
            <p className=" text-white">{userProfile?.type}</p>
            <div className="flex items-center gap-4 mt-5">
              <span className="flex items-center text-sm text-white">
                <BiBuildingHouse size={25}  className="font-bold text-primary"/>
                {userProfile?.address}
              </span>
              <span className="flex items-center text-sm text-white">
                <CiLocationOn size={25} className="font-bold text-primary"/>
                Dhaka
              </span>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1 mt-8">
              <div>
                <TbGenderEpicene size={25} className="text-primary font-bold text-center"/>
                <p className="text-white font-bold text-sm">{userProfile?.gender}</p>
              </div>
              <div>
                <BsEnvelope size={25} className="text-primary font-bold text-center"/>
                <p className="text-white font-bold text-sm">{userProfile?.email}</p>
              </div>
              <div>
                <BsTelephone size={25} className="text-primary font-bold text-center"/>
                <p className="text-white font-bold text-sm">{userProfile?.phone}</p>
              </div>
              <div>
                <CiLocationOn size={25} className="text-primary font-bold text-center"/>
                <p className="text-white font-bold text-sm">{userProfile?.address}</p>
              </div>
              <div>
                <CiCalendarDate size={25} className="text-primary font-bold text-center"/>
                <p className="text-white font-bold text-sm">{moment(userProfile?.dob).format('MMMM Do YYYY, h:mm:ss a')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-8">
          <h1 className="font-bold">Professional Bio</h1>
          <Text
              classes="text-gray-300"
            text="I am MD RASEL here by conclude that,i am the founder and managing director of the prestigeous company name laugh at all and acts as the cheif executieve officer of the company."
          />
        </div>
        <div className="p-8">
          <h1 className="font-bold">Present Address</h1>
          <Text
            classes="text-gray-300"
            text="I am MD RASEL here by conclude that,i am the founder and managing director of the prestigeous company name laugh at all and acts as the cheif executieve officer of the company."
          />
        </div>
        <div className="p-8">
          <h1 className="font-bold">Social Networks</h1>
          <ul className="flex items-center">
            <li className="mr-2 h-10 w-10 bg-green-100 flex items-center justify-center rounded">
              <AiOutlineFacebook size={20} className="text-green-500 cursor-pointer " />
            </li>
            <li className="mr-2 h-10 w-10 bg-red-100 flex items-center justify-center rounded">
              <AiFillLinkedin size={20} className="text-red-500 cursor-pointer" />
            </li>
            <li className="mr-2 h-10 w-10 bg-blue-100 flex items-center justify-center rounded">
              <AiFillTwitterSquare size={20} className="text-blue-500 cursor-pointer" />
            </li>
            <li className="mr-2 h-10 w-10 bg-teal-100 flex items-center justify-center rounded">
              <AiOutlineWhatsApp size={20} className="text-teal-500 cursor-pointer" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Profile;
