// import React, { useState } from 'react';
//
// const Tab = ({ icon, label, isActive, onClick }: any) => {
//   return (
//     <div
//       className={`flex  items-center space-x-2 px-4 py-2 cursor-pointer ${isActive ? 'border-b-2 border-[#12AB81]' : ''}`}
//       onClick={onClick}
//     >
//       {icon}
//       <span className={`${isActive ? 'text-[#12AB81]' : ''}`}>{label}</span>
//     </div>
//   );
// };
//
// const Tabs = ({ items }: any) => {
//   const [activeTab, setActiveTab] = useState<any>(0);
//
//   return (
//     <div className="w-full">
//       <div className="flex border-b">
//         {items?.length>0 && items?.map((tab: any, index: any) => (
//           <Tab
//             key={index}
//             icon={tab?.icon}
//             label={tab?.label}
//             isActive={index === activeTab}
//             onClick={() => setActiveTab(index)}
//           />
//         ))}
//       </div>
//       <div className="p-4">
//         {items[activeTab]?.content}
//       </div>
//     </div>
//   );
// };
//
// export default Tabs;


import React, { useState, useEffect } from 'react';

const Tab = ({ icon, label, isActive, onClick }: any) => {
    return (
        <div
            className={`flex items-center space-x-2 px-4 py-2 cursor-pointer ${isActive ? 'border-b-2 border-[#12AB81]' : ''}`}
            onClick={onClick}
        >
            {icon}
            <span className={`${isActive ? 'text-[#12AB81]' : ''}`}>{label}</span>
        </div>
    );
};

const Tabs = ({ items = [] }: any) => {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
       
        if (activeTab >= items.length) {
            setActiveTab(0);  // Reset activeTab if it goes out of bounds
        }
    }, [items, activeTab]);


    return (
        <div className="w-full">
            <div className="flex border-b">
                {items?.map((tab: any, index: any) => (
                    <Tab
                        key={tab.id}
                        icon={tab.icon}
                        label={tab.label}
                        isActive={index === activeTab}
                        onClick={() => setActiveTab(index)}
                    />
                ))}
            </div>
            <div className="p-4">
                {items[activeTab]?.content}
            </div>
        </div>
    );
};

export default Tabs;
